<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ title[$i18n.locale] }}
      </h2>
      <a-row type="flex">
        <a-button type="primary" @click="openCreateModal">
          <a-icon type="plus" />Текст
        </a-button>
      </a-row>
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: {
                type: item.type
              }
            })
          "
        >
          {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
    <a-modal
      :visible="modalVisible"
      title="Добавить"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="900px"
    >
      <a-row align="middle" class="flex-baseline sm-mb">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="12">
          <!-- lang translit tabs -->
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row style="margin-top: 25px" type="flex">
        <a-col :span="4" class="form_title sm-mb"> {{ $t("Content") }}: </a-col>
        <a-col v-show="langTab === 'oz'" :span="20">
          <!-- :key="langtab" -->
          <editor
            :value="form.description.oz"
            :initial-value="form.description.oz"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.description.oz = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'uz'" :span="20">
          <!-- :key="langtab" -->
          <editor
            :value="form.description.uz"
            :initial-value="form.description.uz"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.description.uz = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'qr'" :span="20">
          <!-- :key="langtab" -->
          <editor
            :value="form.description.qr"
            :initial-value="form.description.qr"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.description.qr = editorChangeHandler(arguments[0])"
          />
        </a-col>
        <a-col v-show="langTab === 'en'" :span="20">
          <!-- :key="langtab" -->
          <editor
            :value="form.description.en"
            :initial-value="form.description.en"
            :init="{
              ...tinymce.default.props,
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip: 'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }"
            @input="form.description.en = editorChangeHandler(arguments[0])"
          />
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="modalVisible = false"> Закрыть </a-button>
        <a-button type="primary" @click="saveOrgType"> Сохранить </a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

const columns = [
  {
    title: "Название",
    key: "title",
    class: "wanted-fio-td",
    scopedSlots: {
      customRender: "name"
    }
  }
]

export default {
  components: {
    editor: () => import("@tinymce/tinymce-vue")
  },
  data: () => ({
    title: {
      uz: "Virtual qabul",
      oz: "Виртуал қабул",
      ru: "Виртуальная приемная",
      en: "Virtual reception"
    },
    tableProps: [
      {
        title: {
          oz: "Мурожаат",
          uz: "Murojaat",
          qr: "Обращения",
          ru: "Обращения",
          en: "Appeal"
        },
        name: "appeal"
      },
      {
        title: {
          oz: "Мурожаат мавзулари",
          uz: "Murojaat mavzulari",
          qr: "Справочные темы",
          ru: "Справочные темы",
          en: "Help topics"
        },
        name: "help-topics"
      },
      {
        title: {
          oz: "Мурожаат саволлари",
          uz: "Murojaat savollari",
          qr: "Справочные вопросы",
          ru: "Справочные вопросы",
          en: "Help questions"
        },
        name: "help-questions"
      }
    ],
    columns,
    form: {
      description: {
        uz: "",
        en: "",
        qr: "",
        oz: "",
        ru: ""
      }
    },
    langTab: $langPrefix,
    modalVisible: false
  }),
  computed: {
    getKey() {
      return this.langTab
    }
  },
  async mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      try {
        let res = await this.$api.get(
          "/admin/leadership-online-registration/notification/detail/last/"
        )
        console.log(res)
        this.form = {
          description: {
            uz: res.data && res.data.description_uz,
            en: res.data && res.data.description_en,
            qr: res.data && res.data.description_qr,
            oz: res.data && res.data.description_oz,
            ru: res.data && res.data.description_ru
          }
        }
      } catch (error) {
        console.error(error)
        return error
      }
    },
    async saveOrgType() {
      try {
        let data = {
          description_uz: this.form && this.form.description.uz,
          description_en: this.form && this.form.description.en,
          description_qr: this.form && this.form.description.qr,
          description_oz: this.form && this.form.description.oz,
          description_ru: this.form && this.form.description.ru
        }
        let res = await this.$api.post(
          "/admin/leadership-online-registration/notification/create/",
          data
        )
        if (res && res.status === "201") {
          this.$message.success("Успешно сохранено")
          this.modalVisible = false
        }
      } catch (error) {
        console.error(error)
        this.$message.error("Ошибка при сохранении")
      }
    },
    openCreateModal() {
      this.modalVisible = true
    }
  }
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
