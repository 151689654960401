var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-row',{staticClass:"ministry"},[_c('a-row',{staticClass:"content-title",attrs:{"type":"flex"}},[_c('h2',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.title[_vm.$i18n.locale])+" ")]),_c('a-row',{attrs:{"type":"flex"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.openCreateModal}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v("Текст ")],1)],1)],1),_c('a-table',{key:_vm.$route.fullPath,attrs:{"columns":_vm.columns,"data-source":_vm.tableProps},scopedSlots:_vm._u([{key:"name",fn:function(item){return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
            name: item.name,
            params: {
              type: item.type
            }
          })}}},[_vm._v(" "+_vm._s(item.title[_vm.$i18n.locale])+" ")])]}}])}),_c('a-modal',{staticStyle:{"max-height":"500px","max-width":"900px"},attrs:{"visible":_vm.modalVisible,"title":"Добавить","closable":false,"width":"900px"}},[_c('a-row',{staticClass:"flex-baseline sm-mb",attrs:{"align":"middle"}},[_c('a-col',{staticStyle:{"text-align":"right","padding-right":"8px"},attrs:{"span":6}},[_vm._v(" Язык: ")]),_c('a-col',{attrs:{"span":12}},[_c('a-row',{staticClass:"flex-baseline flex-wrap",attrs:{"align":"middle"}},[_c('lang-tabs',{staticClass:"mb-1x",model:{value:(_vm.langTab),callback:function ($$v) {_vm.langTab=$$v},expression:"langTab"}})],1)],1)],1),_c('a-row',{staticStyle:{"margin-top":"25px"},attrs:{"type":"flex"}},[_c('a-col',{staticClass:"form_title sm-mb",attrs:{"span":4}},[_vm._v(" "+_vm._s(_vm.$t("Content"))+": ")]),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.langTab === 'oz'),expression:"langTab === 'oz'"}],attrs:{"span":20}},[_c('editor',{attrs:{"value":_vm.form.description.oz,"initial-value":_vm.form.description.oz,"init":{
            ..._vm.tinymce.default.props,
            setup: (editor) => {
              editor.ui.registry.addButton('spoiler-btn', {
                icon: 'comment-add',
                tooltip: 'Спойлер',
                onAction: () => {
                  _vm.spoiler.modalVisibility = editor
                  _vm.spoilerHandler()
                }
              })
              editor.ui.registry.addButton('storage-btn', {
                icon: 'non-breaking',
                tooltip: 'Хранилище изображений',
                onAction: () => {
                  _vm.openStoreModal()
                  _vm.editorInstance = editor
                }
              })
            }
          }},on:{"input":function($event){_vm.form.description.oz = _vm.editorChangeHandler(arguments[0])}}})],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.langTab === 'uz'),expression:"langTab === 'uz'"}],attrs:{"span":20}},[_c('editor',{attrs:{"value":_vm.form.description.uz,"initial-value":_vm.form.description.uz,"init":{
            ..._vm.tinymce.default.props,
            setup: (editor) => {
              editor.ui.registry.addButton('spoiler-btn', {
                icon: 'comment-add',
                tooltip: 'Спойлер',
                onAction: () => {
                  _vm.spoiler.modalVisibility = editor
                  _vm.spoilerHandler()
                }
              })
              editor.ui.registry.addButton('storage-btn', {
                icon: 'non-breaking',
                tooltip: 'Хранилище изображений',
                onAction: () => {
                  _vm.openStoreModal()
                  _vm.editorInstance = editor
                }
              })
            }
          }},on:{"input":function($event){_vm.form.description.uz = _vm.editorChangeHandler(arguments[0])}}})],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.langTab === 'qr'),expression:"langTab === 'qr'"}],attrs:{"span":20}},[_c('editor',{attrs:{"value":_vm.form.description.qr,"initial-value":_vm.form.description.qr,"init":{
            ..._vm.tinymce.default.props,
            setup: (editor) => {
              editor.ui.registry.addButton('spoiler-btn', {
                icon: 'comment-add',
                tooltip: 'Спойлер',
                onAction: () => {
                  _vm.spoiler.modalVisibility = editor
                  _vm.spoilerHandler()
                }
              })
              editor.ui.registry.addButton('storage-btn', {
                icon: 'non-breaking',
                tooltip: 'Хранилище изображений',
                onAction: () => {
                  _vm.openStoreModal()
                  _vm.editorInstance = editor
                }
              })
            }
          }},on:{"input":function($event){_vm.form.description.qr = _vm.editorChangeHandler(arguments[0])}}})],1),_c('a-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.langTab === 'en'),expression:"langTab === 'en'"}],attrs:{"span":20}},[_c('editor',{attrs:{"value":_vm.form.description.en,"initial-value":_vm.form.description.en,"init":{
            ..._vm.tinymce.default.props,
            setup: (editor) => {
              editor.ui.registry.addButton('spoiler-btn', {
                icon: 'comment-add',
                tooltip: 'Спойлер',
                onAction: () => {
                  _vm.spoiler.modalVisibility = editor
                  _vm.spoilerHandler()
                }
              })
              editor.ui.registry.addButton('storage-btn', {
                icon: 'non-breaking',
                tooltip: 'Хранилище изображений',
                onAction: () => {
                  _vm.openStoreModal()
                  _vm.editorInstance = editor
                }
              })
            }
          }},on:{"input":function($event){_vm.form.description.en = _vm.editorChangeHandler(arguments[0])}}})],1)],1),_c('template',{slot:"footer"},[_c('a-button',{key:"back",on:{"click":function($event){_vm.modalVisible = false}}},[_vm._v(" Закрыть ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.saveOrgType}},[_vm._v(" Сохранить ")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }